
















































































































































































































































import {Component} from "vue-property-decorator";
import {ComponentBase, Overlay} from "@/modules/budget/staffing-table/components";
import i18n from "@/services/i18n";
import auth from "@/services/auth";
import axios from "axios";
import {BudgetVariants, Comp, Dict} from "@/modules/budget/staffing-table/types";
import BudgetRegions = Dict.BudgetRegions;
import BudgetDataTypes = Dict.BudgetDataTypes;
import EbkFunc = Dict.EbkFunc;

const translates = {
    filterTools: {
        get filterTitle(): string {
            return i18n.choose('Фильтр', 'Фильтр (каз)', 'Filter');
        },
        get filterParamTitle(): string {
            return i18n.choose('Параметры фильтра', 'Параметры фильтра (каз)', 'Filter parameters');
        },
        get planPeriodTitle(): string {
            return i18n.choose('Плановый период', 'Плановый период (каз)', 'Calendar plan');
        },
        get chooseTitle(): string {
            return i18n.choose('Выберите данные', 'Выберите данные (каз)', 'Choose data');
        },
        get regionTitle(): string {
            return i18n.choose('Регион', 'Регион (каз)', 'Region');
        },
        get dataType(): string {
            return i18n.choose('Вид данных', 'Вид данных (каз)', 'Data type');
        },
        get budgetVariantTitle(): string {
            return i18n.choose('Вариант бюджета', 'Вариант бюджета (каз)', 'Budget variant');
        },
        get budgetVariantApprovedTitle(): string {
            return i18n.choose('утв', 'Approved (каз)', 'Approved');
        },
        get budgetVariantActualTitle(): string {
            return i18n.choose('акт', 'акт (каз)', 'act');
        },
        get abpTitle(): string {
            return i18n.choose('АБП', 'АБП (каз)', 'ABP');
        },
        get abpEducationDep(): string {
            return i18n.choose('Отдел образования', 'Отдел образования (каз)', 'Education department');
        },
        get EduTypeOrg(): string {
            return i18n.choose('Тип организации', 'Тип организации (каз)', 'Education org type');
        },
        get subEducationDep(): string {
            return i18n.choose('Организации отдела образования', 'Организации отдела образования (каз)', 'Organization department education');
        },
        get selectedEduDep(): string {
            return i18n.choose('Выбрано отделов', 'Выбрано отделов (каз)', 'selected department');
        },
        get budgetProgram(): string {
            return i18n.choose('Бюджетная программа', 'Бюджетная программа (каз)', 'Budget program');
        },
        get withDepartments(): string {
            return i18n.choose('в разрезе отделов', 'в разрезе отделов (каз)', 'by departments');
        },
        get detailedReport(): string {
            return i18n.choose('расширенный отчет', 'расширенный отчет (каз)', 'detailed Report');
        },
        get loadReport(): string {
            return i18n.choose('Выгрузить штатное расписание', 'Выгрузить штатное расписание (каз)', 'staffing table');
        },
        get loadTariffsReport(): string {
            return i18n.choose('Выгрузить тарификационный список', 'Выгрузить тарификационный список (каз)', 'Generate a tariff report');
        },
        get loadStaffTabEduReport(): string {
            return i18n.choose('Выгрузить штатное расписание', 'Выгрузить штатное расписание (каз)', 'Generate a staffing table');
        },
        get chooseAll(): string {
            return i18n.choose('Все', 'Барлығы', 'All');
        },

        get chooseWithoutPrg(): string {
            return i18n.choose('Без БП ', 'Без БП', 'Without BP');
        },

    }
};

interface GuAbp261Response {
     addAll: boolean,
     data: Array<Dict.Gu>
}

@Component({
    name: 'Page',
    components: {Overlay}
})
export default class Page extends ComponentBase {
    constructor() {
        super('');
    }

    private SVD_ORG_UO = "SVD_ORG_UO"
    private SVD_EDU = "SVD_EDU"
    private SVD_EDU_SHR = "SVD_EDU_SHR"

    public translates = translates;

    // region Misc data
    private get userId(): string {
        return auth.user.sub;
    }

    public get loading(): boolean {
        return this.filterLoading
    }

    public filterLoading = false;

    private get showReportLoading(): boolean {
        const variant = this.selectedBudgetVariant?.value ?? null
        const abp = this.selectedAbp?.value ?? null

        return variant !== null && abp !== null
    }


    protected created() {
        this.$watch('selectedPeriod', async () => {
            this.selectedProgram = null;
            await this.loadBudgetVariantYearAndRegionAndDataType();
        });


        this.$watch('selectedRegion',  async () => {
            this.selectedProgram = null

           await this.loadBudgetVariantYearAndRegionAndDataType()
           await this.loadEbkFuncAbp()
        })

        this.$watch('selectedDataType', async () => {
            this.selectedProgram = null
            await this.loadBudgetVariantYearAndRegionAndDataType()

        })

        this.$watch('selectedBudgetVariant', () => {
            this.selectedProgram = null
            this.loadEbkFuncProgram()

        })

        this.$watch('selectedAbp', async () => {
            this.listOfGuEducation = []
            this.listOfSubGuEducation = []
            this.selectedSubGuEducation = []
            this.selectedGuEducation = null
            this.selectedEduOrgType = null
            this.selectedProgram = null

            if (this.isEducationSetting) {
               await this.loadEducationsFilter()
            }
        })

        this.$watch('selectedGuEducation', async () => {
            if (this.isEducationSetting)
                await this.loadSubGuEducationAbp261()
        })

        this.$watch('cachedOptionsGuEducation', () => {
            this.selectedGuEducation = this.cachedOptionsGuEducation[0] ?? null
        })

        this.$watch('selectedSubGuEducation', () => {
                if (this.selectedSubGuEducation.some(item => item.value == null)) {
                    this.selectedSubGuEducation = [];
                }
             this.loadEbkFuncProgram();
        });
    }

    protected async mounted() {
        await this.loadRegionsByUserId()
        await this.loadBudgetDataTypes()
    }

    private withDepartments = false;
    private detailedReport = false
    public allowSelectAll = false

    private selectedPeriod: Comp.DropdownItemDef<number> | null = null;
    private get optionByPeriod(): Array<Comp.DropdownItemDef<number>> {
        const periodLst: { text: string; value: number }[] = [];
        const year = new Date().getFullYear();

        for (let i = year - 2020 + 1; i > 0; i--) {
            periodLst.push({ text: `${2020 + i} - ${2020 + i + 2}`, value: 2020 + i });
        }
        this.selectedPeriod = periodLst.find(item => item.value === year) || null;
        return periodLst;
    }
    // endregion


    private selectedRegion: Comp.DropdownItemDef<string> | null = null;
    private listOfBudgetRegions: BudgetRegions[]  = [];

    private async loadEducationsFilter() {
        this.filterLoading = true
        await this.loadGuEducationAbp261()
        await this.loadEduOrgType()
        this.filterLoading = false

    }


  private createDropdownRegion(region: BudgetRegions): Comp.DropdownItemDef<string> {
      const code = region.code ?? (() => { throw new Error("BudgetRegions.code is null or undefined"); })();


      const title = this.localize(
          () => (`${code} - ${region.nameRu}`|| ''),
          () => (`${code} - ${region.nameKk}`|| ''),
          () => (`${code} - ${region.nameRu}`|| '')
      )
      return { text: title, value: code }
  }

    private get optionsRegion(): Array<Comp.DropdownItemDef<string>> {
        return this.listOfBudgetRegions.map(region => this.createDropdownRegion(region))
    }

    private async loadRegionsByUserId() {
        const userId = this.userId;

        try {
            const response = await axios.get<Array<BudgetRegions>>(
                `/api/budget/staffing_table/budget-regions/by-user-id/${userId}`
            );
            this.listOfBudgetRegions = response.data;
            const activeRegion = this.listOfBudgetRegions[0]
            if (activeRegion) {
                this.selectedRegion = this.createDropdownRegion(activeRegion)
            }

        } catch (error) {
            console.error(`Error loading regions for: ID ${userId}:`, error);
            return null;
        }
    }
    // endregion

    private selectedDataType: Comp.DropdownItemDef<string> | null = null;
    private listOfDataType: BudgetDataTypes[] = [];

    private get optionsDataType(): Array<Comp.DropdownItemDef<string>> {
        return this.listOfDataType.map(dataType => this.createDropdownDataType(dataType))
    }

    private createDropdownDataType(dataType: BudgetDataTypes): Comp.DropdownItemDef<string> {
        const code = dataType.code?? (() => { throw new Error("BudgetDataTypes.code is null or undefined"); })();

        const title = this.localize(
            () => (`${code} - ${dataType.nameRu}`|| ''),
            () => (`${code} - ${dataType.nameKk}`|| ''),
            () => (`${code} - ${dataType.nameRu}`|| '')
        )
        return { text: title, value: code }
    }

    private async loadBudgetDataTypes() {
        try {
            const response = await axios.get<Array<BudgetDataTypes>>(
                `/api/budget/staffing_table/budget-data-type`
            );
            this.listOfDataType = response.data;
            const activeDataType = this.listOfDataType[0]

            if (activeDataType) {
                this.selectedDataType = this.createDropdownDataType(activeDataType)
            }

        } catch (error) {
            console.error(`Error loading data-type`, error);
            return null;
        }
    }
    // endregion

    private selectedBudgetVariant: Comp.DropdownItemDef<string> | null = null;
    private listOfBudgetVariants: BudgetVariants[] = [];

    private createDropdownBudgetVariant(variant: BudgetVariants): Comp.DropdownItemDef<string> {
        const variantUuid = variant.variantUuid

        const statuses: Array<string> = [];
        if (variant.attribute) {
            statuses.push(translates.filterTools.budgetVariantActualTitle);
        }
        if (variant.status) {
            statuses.push(translates.filterTools.budgetVariantApprovedTitle);
        }

        let status: string;
        if (statuses.length > 0) {
            status = ` (${statuses.join(', ')})`;
        } else {
            status = '';
        }

        const title = this.localize(
            () => (`${variant.nameRu}${status}`|| ''),
            () => (`${variant.nameKk}${status}`|| ''),
            () => (`${variant.nameRu}${status}`|| '')
        )
        return { text: title, value: variantUuid }
    }

    private get optionsBudgetVariant(): Array<Comp.DropdownItemDef<string>> {
        return this.listOfBudgetVariants.map(variant => this.createDropdownBudgetVariant(variant))
    }

    private async loadBudgetVariantYearAndRegionAndDataType() {
        const year = this.selectedPeriod?.value ?? null;
        const regionCode = this.selectedRegion?.value ?? null;
        const dataType = this.selectedDataType?.value ?? null;

        if (year === null || regionCode === null || dataType === null) {
            return;
        }

        try {
            const response = await axios.get<Array<BudgetVariants>>(
                `/api/budget/staffing_table/budget-variants/by-year-region-datatype`,
                {
                    params: { 'year': year, 'region': regionCode, 'dataType': dataType }
                }

            );
            this.listOfBudgetVariants = response.data;
            const activeVariant = this.listOfBudgetVariants[0]

            if (activeVariant) {
                this.selectedBudgetVariant = this.createDropdownBudgetVariant(activeVariant)
            } else
                this.selectedBudgetVariant = null

        } catch (error) {
            console.error(`Error loading budget-variants with: year-${year}, regionCode-${regionCode}, dataType-${dataType} `, error);
            return null;
        }


    }
    // endregion

    private selectedAbp: Comp.DropdownItemDef<number> | null = null;
    private listOfEbkFuncAbp: EbkFunc[] = []

    private createDropdownAbp(ebkFunc: EbkFunc): Comp.DropdownItemDef<number> {
        const abp = ebkFunc.abp ?? (() => { throw new Error("ebkFunc.abp is null or undefined"); })();

        const title = this.localize(
            () => (`${abp} - ${ebkFunc.nameRu}`|| ''),
            () => (`${abp} - ${ebkFunc.nameKk}`|| ''),
            () => (`${abp} - ${ebkFunc.nameRu}`|| '')
        )
        return { text: title, value: abp }
    }

    private get optionsAbp(): Array<Comp.DropdownItemDef<number>> {
        return this.listOfEbkFuncAbp.map(ebkFunc => this.createDropdownAbp(ebkFunc))
    }

    private async loadEbkFuncAbp() {
        const regionCode = this.selectedRegion?.value ?? null;
        const userId = this.userId;

        if (regionCode === null) {
            this.selectedAbp = null
            return;
        }

        try {
            const response = await axios.get<Array<EbkFunc>>(
                `/api/budget/staffing_table/abp-by-user`,
                { params: { 'userId': userId, 'regionCode': regionCode } }
            );
            this.listOfEbkFuncAbp = response.data;
            const ebkFunc = this.listOfEbkFuncAbp[0]

            if (ebkFunc) {
                this.selectedAbp = this.createDropdownAbp(ebkFunc)
            } else
                this.selectedAbp = null

        } catch (error) {
            this.selectedAbp = null
            console.error(`Error loading Abp for: ID:${userId}, regionCode:${regionCode}`, error);
            return null;
        }
    }
    // endregion

    private selectedProgram: Comp.DropdownItemDef<number> | null = null;
    private listOfEbkFuncProgram: EbkFunc[] = []

    private get optionsProgram(): Array<Comp.DropdownItemDef<number | null>> {
        return [
            ...(this.listOfEbkFuncProgram.length > 0 ?
                [{ text: this.localize(
                        () => ('without BP'),
                        () => ('без БП'),
                        () => ('без БП'),
                    ),
                    value: null }] : []),
            ...this.listOfEbkFuncProgram.map(this.createDropdownProgram)
        ];
    }



    private createDropdownProgram(ebkFunc: EbkFunc): Comp.DropdownItemDef<number | null> {
        const id = ebkFunc.id
        let code = String(ebkFunc.prg);
        while (code.length < 3) code = '0' + code;


        const title = this.localize(
            () => (`${code} - ${ebkFunc.nameRu}`|| ''),
            () => (`${code} - ${ebkFunc.nameKk}`|| ''),
            () => (`${code} - ${ebkFunc.nameRu}`|| '')
        )
        return { text: title, value: id }
    }

    private async loadEbkFuncProgram() {
        const year = this.selectedPeriod?.value ?? null;
        const regionCode = this.selectedRegion?.value ?? null;
        const uuidVariant = this.selectedBudgetVariant?.value ?? null;
        const abp = this.selectedAbp?.value ?? null;

        const selectedGuIsNull = this.selectedGuEducation === null

        if (year === null || regionCode === null || uuidVariant === null || abp === null) {
            return;
        }

       if (this.isEducationSetting && selectedGuIsNull) {
            return
        }

        const listGu = this.selectedSubGuEducation.length === 0 && this.listOfSubGuEducation.length > 0
            ? this.optionsSubGuEducation.map(gu => gu.value ?? 'none')
            : this.selectedSubGuEducation.map(gu => gu.value ?? 'none');


        try {
            const parameterMap: Record<string, string | number | string[]> = {
                'year': year,
                'region': regionCode,
                'uuid-variant': uuidVariant,
                'abp': abp,
                'list-gu': listGu
            };
            const response = await axios.get<Array<EbkFunc>>(
                `/api/budget/staffing_table/budget-programs-from-budget-request`,
                { params: parameterMap,  paramsSerializer: params => new URLSearchParams(params).toString() }
            );
            this.listOfEbkFuncProgram = response.data;


        } catch (error) {
            console.error(`Error loading programs for: year:${year}, regionCode:${regionCode}, uuidVariant:${uuidVariant}, abp:${abp}`, error);
            return null;
        } finally {
            this.selectedProgram = null
        }
    }
    // endregion

    private selectedGuEducation: Comp.DropdownItemDef<string | null> | null = null;
    private listOfGuEducation: Dict.Gu[]  = []
    private cachedOptionsGuEducation : Array<Comp.DropdownItemDef<string | null>> =[]

    private get isEducationSetting(): boolean {
        return this.selectedAbp?.value === 261
   }

    private get showTarifficationBtn(): boolean {
        return !!(this.selectedProgram || this.selectedEduOrgType?.value);
    }

    private get showStaffTabEducationBtn(): boolean {
        return this.isEducationSetting;
    }


    private createDropdownGuEducation(gu: Dict.Gu): Comp.DropdownItemDef<string | null> {
        const code = gu.code
        const title = this.localize(
            () => (`${code} - ${gu.nameRu}`|| ''),
            () => (`${code} - ${gu.nameKk}`|| ''),
            () => (`${code} - ${gu.nameRu}`|| '')
        )
        return { text: title, value: code }
    }

    private get optionsGuEducation(): Array<Comp.DropdownItemDef<string | null>> {
        const addAllOption = this.listOfGuEducation.length > 0 && this.allowSelectAll;

        return [
            ...(addAllOption ? [{
                text: this.localize(
                    () => ('All'),
                    () => ('Барлығы'),
                    () => ('Все'),
                ),
                value: null
            }] : []),
            ...this.listOfGuEducation.map(this.createDropdownGuEducation)
        ];
    }

    private async loadGuEducationAbp261() {
        this.allowSelectAll = false
        const uuidVariant = this.selectedBudgetVariant?.value ?? null;
        if (uuidVariant == null) {
            console.error('Cannot define uuid-variant');
            return;
        }

        const region = this.selectedRegion?.value ?? null
        if (region == null) {
            console.error('Cannot define region');
            return;
        }

        try {
            const response = await axios.get<GuAbp261Response>(
                `/api/budget/staffing_table/budget-execution-261`,
                { params: { 'region': region, 'uuid-variant': uuidVariant } }
            );
            this.listOfGuEducation = response.data.data;
            this.allowSelectAll = response.data.addAll
            this.cachedOptionsGuEducation = this.optionsGuEducation
        } catch (error) {
            console.error(`Error loading gu-education: 261 `, error);
            return null;
        }
    }
    // endregion

    private selectedEduOrgType: Comp.DropdownItemDef<string> | null = null;
    private listOfEduOrgType: Dict.StEduOrgType[] = []

    private createDropdownEduOrgType(orgType: Dict.StEduOrgType): Comp.DropdownItemDef<string | null> {
        const code = orgType.code
        const title = this.localize(
            () => (`${code} - ${orgType.nameRu}`|| ''),
            () => (`${code} - ${orgType.nameKk}`|| ''),
            () => (`${code} - ${orgType.nameRu}`|| '')
        )
        return { text: title, value: code }
    }

    private get optionsEduOrgType(): Array<Comp.DropdownItemDef<string | null>> {
        return [
            ...(this.listOfEduOrgType.length > 0 ? [{
                text: this.localize(
                    () => ('Not selected'),
                    () => ('Не выбрано'),
                    () => ('Не выбрано'),
                ),
                value: null }] : []),
            ...this.listOfEduOrgType.map(this.createDropdownEduOrgType)
        ];
    }


    private async loadEduOrgType() {
        try {
            const response = await axios.get<Array<Dict.StEduOrgType>>(
                `/api/budget/staffing_table/education-org-type`
            );
            this.listOfEduOrgType = response.data;
        } catch (error) {
            console.error(`Error loading education-org-type`, error);
            return null;
        }
    }
    // endregion


    private selectedSubGuEducation: Comp.DropdownItemDef<string| null> [] = [];
    private listOfSubGuEducation: Dict.Gu[]  = []

    private get selectedCodes(): string[] {
        return this.selectedSubGuEducation
            .flatMap(item => item.value !== null ? [item.value] : []);
    }

    public unselect(item: Comp.DropdownItemDef<string>, event: Event) {
        event.stopPropagation();
        const index = this.selectedCodes.indexOf(item.value);
        if (index >= 0) {
            this.selectedSubGuEducation.splice(index, 1);
        }

    }

    private get optionsSubGuEducation(): Array<Comp.DropdownItemDef<string | null>> {
        return [
            ...(this.listOfSubGuEducation.length > 0 ? [{
                text: this.localize(
                    () => ('All'),
                    () => ('Барлығы'),
                    () => ('Все'),
                ),
                value: null }] : []),
            ...this.listOfSubGuEducation.map(this.createDropdownGuEducation)
        ];
    }

    private async loadSubGuEducationAbp261() {
        const uuidVariant = this.selectedBudgetVariant?.value ?? null;
        const region = this.selectedRegion?.value ?? null;
        const selectedGuEducation = this.selectedGuEducation;

        if (!uuidVariant || !region || selectedGuEducation === null) {
            this.selectedSubGuEducation = [];
            this.listOfSubGuEducation = []

            console.error(
                !uuidVariant ? 'Cannot define uuid-variant' :
                    !region ? 'Cannot define region' :
                        'Cannot define selectedGuEducation'
            );

            return;
        }

        const codeGu = this.selectedGuEducation?.value ?? null
        try {
            const response = await axios.get<Array<Dict.Gu>>(
                `/api/budget/staffing_table/budget-sub-execution-261`,
                { params: { 'codeGu': codeGu, 'region': region, 'uuid-variant': uuidVariant } }
            );
            this.listOfSubGuEducation = response.data;
        } catch (error) {
            this.listOfSubGuEducation = []
            console.error(`Error loading  sub-gu-education: 261 `, error);
            return null;
        } finally {
            this.selectedSubGuEducation = [];
        }
    }

    generateReport(typeGenerator: string) {

        if (this.isEducationSetting && this.listOfSubGuEducation.isEmpty) {
            console.error('Cannot load education report when list of gu is null');
            return;
        }

        let eduOrgType: string | null = null;


        const region = this.selectedRegion?.value ?? null
        if (region == null) {
            console.error('Cannot define region');
            return;
        }

        const dataType = this.selectedDataType?.value ?? null
        if (dataType == null) {
            console.error('Cannot define dataType');
            return;
        }

        const variant = this.selectedBudgetVariant?.value ?? null
        if (variant == null) {
            console.error('Cannot define variant');
            return;
        }

        const abp = this.selectedAbp?.value ?? null
        if (abp == null) {
            console.error('Cannot define abp');
            return;
        }

        const guEducation = this.selectedGuEducation?.value ?? null

        const budgetProgramId = this.selectedProgram?.value ?? null

        if (this.isEducationSetting) {
            eduOrgType = this.selectedEduOrgType?.value ?? null
        }

        const locale = i18n.locale

        const parameterMap: Array<[string, unknown]> = [
            ['region', region],
            ['data-type', dataType],
            ['variant', variant],
            ['abp', abp],
            ['gu-code', guEducation],
            ['budget-program-id', budgetProgramId],
            ['gu-org-type', eduOrgType],
            ['include-department', this.withDepartments],
            ['locale', locale],
            ['detailed-report', this.detailedReport],
            ['gen-type', typeGenerator],
        ];

        if (this.selectedSubGuEducation.length === 0 && this.listOfSubGuEducation.length > 0) {
            this.optionsSubGuEducation.forEach((gu) => {
                parameterMap.push(['sub-gu-education-list', gu.value]);
            });
        } else if (this.selectedSubGuEducation.length > 0) {
            this.selectedSubGuEducation.forEach((gu) => {
                parameterMap.push(['sub-gu-education-list', gu.value]);
            });
        } else {
            parameterMap.push(['sub-gu-education-list', '']);
        }

        const url = this.prepareUrl(
            '/api/budget/staffing_table/generate-aggr-report',
            parameterMap

        );
        this.openUrl(url);
    }
}
